import React from 'react';
import '../styles/OrderCard.css';
import { formatDate } from './utils/FormatChange'

const OrderCard = ({ orders, onDetailView, user }) => {
  return (
    <div className="order-card-list">
      {orders.length > 0 ? (
        orders.map(order => (
          <div
            key={order.id}
            className="order-card clickable"
            onClick={() => onDetailView(order.id)}
          >
            {user ? <p className="order-date">사용자: {order.user?.username}</p> : null}
            <p className="order-date">주문 날짜: {formatDate(order.created_at)}</p>
            <p className="order-amount">주문 금액: {order.amount.toLocaleString()} 원</p>
            <p className="order-status">상태: {order.status}</p>
          </div>
        ))
      ) : (
        <p>주문 내역이 없습니다.</p>
      )}
    </div>
  );
};

export default OrderCard;
