import React, {useState} from "react";
import '../styles/Login.css'
import key_icon from '../assets/password.png'
import mail_icon from '../assets/email.png'
import user_icon from '../assets/person.png'
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSignup from "../components/customhook/useSignup";

const Signup = () => {
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const {signup, loading, error} = useSignup();  // 커스텀 훅 사용
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const referral = searchParams.get('referral') || '9a802537';

        const handleSignup = async () => {
            await signup(username, email, password, referral)
            navigate('/login');
    };


    return (
        <div className='container'>
            <div className="header">
                <div className="text">Sign Up</div>
                <div className="underline"></div>
            </div>
            <div className="inputs">
                <div className="input">
                    <img src={user_icon} alt="email icon"/>
                    <input type="text" placeholder="username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                </div>
                <div className="input">
                    <img src={mail_icon} alt="email icon"/>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="input">
                    <img src={key_icon} alt="password icon"/>
                    <input type="password" placeholder="Password" value={password}
                           onChange={(e) => setPassword(e.target.value)}/>
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="submit-container">
                <div className="submit" onClick={handleSignup}>
                    {loading ? 'Loading...' : 'Sign UP'}
                </div>
            </div>
        </div>
    )
}

export default Signup;