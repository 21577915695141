import React from 'react';
import { formatAmount } from './utils/FormatChange'

const PartnerTable = ({partnerData, handleRowClick}) => {
    const totals = partnerData.reduce((acc, partner) => {
        const available = Number(partner.partnerData?.coinone_data?.available) || 0;
        const withdrawAvailable = Number(partner.partnerData?.coinone_data?.withdraw_available) || 0;
        const availableAmount = Number(partner.partnerData?.coinone_data?.withdraw_coin_amount) || 0
        return {
            totalAvailable: acc.totalAvailable + available,
            totalWithdrawAvailable: acc.totalWithdrawAvailable + withdrawAvailable,
            totalavailableAmount: acc.totalavailableAmount + availableAmount,
        };
    }, {totalAvailable: 0, totalWithdrawAvailable: 0, totalavailableAmount: 0});


    return (
        <table className="partner-list-table">
            <thead>
            <tr>
                <th>이름</th>
                <th>보유원화</th>
                <th>사용가능금액</th>
                <th>시세</th>
                <th>사용가능수량</th>
                <th>최근입금일자</th>
                <th>입금금액</th>
            </tr>
            </thead>
            <tbody>
            <tr className="total-row">
                <td>합계</td>
                <td className="right-align">{formatAmount(totals.totalAvailable, 0)}</td>
                <td className="right-align">{formatAmount(totals.totalWithdrawAvailable, 0)}</td>
                <td className="right-align">-</td>
                <td className="right-align">{formatAmount(totals.totalavailableAmount, 0)}</td>
                <td className="right-align">-</td>
                <td className="right-align">-</td>
            </tr>
            {partnerData.map((partner) => {
                return (
                    <tr key={partner.id} onClick={() => handleRowClick(partner)}>
                        <td>{partner.username}</td>
                        <td className="right-align">{formatAmount(partner.partnerData?.coinone_data?.available, 0)}</td>
                        <td className="right-align">{formatAmount(partner.partnerData?.coinone_data?.withdraw_available, 0)}</td>
                        <td className="right-align">{formatAmount(partner.partnerData?.coinone_data?.last_price, 0)}</td>
                        <td className="right-align">{formatAmount(partner.partnerData?.coinone_data?.withdraw_coin_amount, 0)}</td>
                        <td className="right-align">{partner.partnerData?.coinone_data?.last_deposit}</td>
                        <td className="right-align">{formatAmount(partner.partnerData?.coinone_data?.deposit_amount, 0)}</td>
                    </tr>
                )
            })}
                </tbody>
                </table>
                );
            };

export default PartnerTable;
