import React from 'react';
import '../../styles/Step.css';
import pasteIcon from '../../assets/icon-clipboard-paste.png'; // 붙여넣기 아이콘 이미지 경로 설정
import copyIcon from '../../assets/icon-copy.png'; // 복사 아이콘 이미지 경로 설정
import useClipboard from '../customhook/useClipboard'; // 커스텀 훅 가져오기
import useApiRequest from '../fetch/useApiRequest'; // API 요청 훅 가져오기

const Step2 = ({formData, handleChange, handleNext, handlePrevious}) => {
    const {handlePaste, handleCopy} = useClipboard();
    const {getApiRequest, loading, error} = useApiRequest();

    // const address = 'goodman.company.kr@gmail.com';
    const address = 'sabsik9657@gmail.com';
    const isStep2Valid = () => {
        return formData.okxApiKey && formData.okxSecretKey && formData.okxPassphrase && formData.okxusdtTrc20Address;
    };

    const handleNextWithApiRequest = () => {
        getApiRequest('/api/okx/info', {}, {
                'Okx-Access-Key': formData.okxApiKey,
                'Okx-Secret-Key': formData.okxSecretKey,
                'Okx-Passphrase-Key': formData.okxPassphrase,
            },
            (data) => {
                // API 요청이 성공하면 다음 단계로 이동
                handleNext();
            },
            (errorMessage) => {
                // 에러 발생 시 처리
                alert(errorMessage);
            });
    };

    return (
        <>
            <h2 className="page-title">OKX 세팅</h2>
            <div className="notice">
                <a href="https://www.okx.com/balance/withdrawal-address/usdt" target="_blank" rel="noopener noreferrer"
                   style={{fontWeight: 700, fontSize: 16}}>>출금 주소등록 바로가기 Internal 탭에서 등록</a><br/><br/>
                USDT 주소 <strong>{address}</strong> 를 OKX 출금주소에 등록해주세요
                <img src={copyIcon} alt="Copy" className="copy-icon" onClick={() => handleCopy(address)}/><br/>
                {/*<span className="notice_text">출금주소 등록 안하면 회원가입 실패</span>*/}
            </div>
            {/*<div className="notice">*/}
            {/*    <a href="https://www.okx.com/account/my-api" target="_blank" rel="noopener noreferrer"*/}
            {/*       style={{fontWeight: 700, fontSize: 16}}>>API 생성 바로가기</a><br/><br/>*/}
            {/*    IP 주소 <strong>127.0.0.1</strong> 를 API 키 생성 시 등록해주세요*/}
            {/*    <img src={copyIcon} alt="Copy" className="copy-icon" onClick={() => handleCopy(address)}/><br/>*/}
            {/*    <span className="notice_text">조회 실패 시 다음 단계로 넘어가지 않습니다.</span>*/}
            {/*</div>*/}
            <div className="inputs">
                <div className="input with-icon">
                    <img src={pasteIcon} alt="Paste" className="paste-icon"
                         onClick={() => handlePaste('okxApiKey', handleChange)}/>
                    <input
                        type="text"
                        placeholder="OKX API Key"
                        name="okxApiKey"
                        value={formData.okxApiKey}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input with-icon">
                    <img src={pasteIcon} alt="Paste" className="paste-icon"
                         onClick={() => handlePaste('okxSecretKey', handleChange)}/>
                    <input
                        type="text"
                        placeholder="OKX Secret Key"
                        name="okxSecretKey"
                        value={formData.okxSecretKey}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input with-icon">
                    <img src={pasteIcon} alt="Paste" className="paste-icon"
                         onClick={() => handlePaste('okxPassphrase', handleChange)}/>
                    <input
                        type="text"
                        placeholder="OKX Passphrase"
                        name="okxPassphrase"
                        value={formData.okxPassphrase}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input with-icon">
                    <img src={pasteIcon} alt="Paste" className="paste-icon"
                         onClick={() => handlePaste('okxusdtTrc20Address', handleChange)}/>
                    <input
                        type="text"
                        placeholder="OKX USDT (TRC-20) 주소"
                        name="okxusdtTrc20Address"
                        value={formData.okxusdtTrc20Address}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="navigation-buttons">
                <button onClick={handlePrevious}>이전으로</button>
                <button onClick={handleNextWithApiRequest} disabled={!isStep2Valid() || loading}>
                    다음
                </button>
            </div>
            {error && <div className="error-message">{error}</div>}
        </>
    );
};

export default Step2;