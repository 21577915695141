import React from 'react';
import '../../styles/Step.css';
import pasteIcon from '../../assets/icon-clipboard-paste.png'; // 붙여넣기 아이콘 이미지 경로 설정
import copyIcon from '../../assets/icon-copy.png'; // 복사 아이콘 이미지 경로 설정
import useClipboard from '../customhook/useClipboard'; // 커스텀 훅 가져오기
import useApiRequest from "../fetch/useApiRequest"; // API 요청 훅 가져오기

const Step3 = ({formData, handleChange, handleNext, handlePrevious}) => {
    const {handlePaste, handleCopy} = useClipboard('127.0.0.1');
    const {getApiRequest, loading, error} = useApiRequest();
    const address = '127.0.0.1';

    const isStep3Valid = () => {
        return formData.coinoneAccessKey && formData.coinoneSecretKey;
    };

    const handleNextWithApiRequest = () => {
        getApiRequest('/api/coinone/info', {}, {
                'Coinone-Access-Key': formData.coinoneAccessKey,
                'Coinone-Secret-Key': formData.coinoneSecretKey,
            },
            (data) => {
                // API 요청이 성공하면 데이터를 처리

                // withdrawal_addresses 배열에서 usdtTrc20Address와 일치하는 주소가 있는지 확인
                const addressExists = data.withdrawal_addresses.some(addressObj =>
                    addressObj.address === formData.okxusdtTrc20Address
                );

                if (addressExists) {
                    // 일치하는 주소가 있으면 다음 단계로 이동
                    handleNext();
                } else {
                    // 일치하는 주소가 없으면 오류 메시지 표시
                    alert('USDT(TRC-20) 주소가 등록되지 않았습니다..');
                }
            },
            (errorMessage) => {
                // 에러 발생 시 처리
                alert(errorMessage);
            });
    };

    return (
        <>
            <h2 className="page-title">코인원 세팅</h2>
            <div className="notice">
                <a href="https://coinone.co.kr/user/api/withdrawal-address/registration" target="_blank"
                   rel="noopener noreferrer" style={{fontWeight: 700, fontSize: 16}}>>출금 주소등록 바로가기</a><br/><br/>
                본인의 OKX 계정의 USDT(TRC-20) 주소를 코인원 API 출금주소에 등록 해주세요<br/>
                <span className="notice_text">출금주소 등록 안하면 다음단계 진행불가.</span>
            </div>
            <div className="notice">
                <a href="https://coinone.co.kr/user/api/management/personal-api" target="_blank"
                   rel="noopener noreferrer"
                   style={{fontWeight: 700, fontSize: 16}}>>API 생성 바로가기</a><br/><br/>
                <img src={copyIcon} alt="Copy" className="copy-icon" onClick={() => handleCopy(address)}/><br/>
                <span className="notice_text">조회 실패시 다음단계 진행불가.</span>
            </div>
            <div className="inputs">
                <div className="input with-icon">
                    <img src={pasteIcon} alt="Paste" className="paste-icon"
                         onClick={() => handlePaste('coinoneAccessKey', handleChange)}/>
                    <input
                        type="text"
                        placeholder="코인원 Access Key"
                        name="coinoneAccessKey"
                        value={formData.coinoneAccessKey}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input with-icon">
                    <img src={pasteIcon} alt="Paste" className="paste-icon"
                         onClick={() => handlePaste('coinoneSecretKey', handleChange)}/>
                    <input
                        type="text"
                        placeholder="코인원 Secret Key"
                        name="coinoneSecretKey"
                        value={formData.coinoneSecretKey}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="navigation-buttons">
                <button onClick={handlePrevious}>이전으로</button>
                <button onClick={handleNextWithApiRequest} disabled={!isStep3Valid() || loading}>
                    다음
                </button>
            </div>
            <div>
                {error && <div className="error-message">{error}</div>}
            </div>
        </>
    );
};

export default Step3;

