import { useState } from 'react';
import axios from 'axios';

const usePartnerSignup = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const partnerSignup = async (formData) => {
        setLoading(true);
        setError(null);
        const BASE_URL = process.env.REACT_APP_API_BASE_URL

        try {
            const response = await axios.post(`${BASE_URL}/register/partner`, {
                username: formData.name,
                email: formData.email,
                password: formData.password,
                referral: formData.referral,  // 수정됨
                user_role: 'partner',  // 수정됨
                phone_number: formData.phone,
                coinone_api_key: formData.coinoneAccessKey,  // 수정됨
                coinone_secret_key: formData.coinoneSecretKey,  // 수정됨
                okx_api_key: formData.okxApiKey,
                okx_secret_key: formData.okxSecretKey,
                okx_passphrase_key: formData.okxPassphrase,
                okx_usdt_address: formData.okxusdtTrc20Address,  // 수정됨
                bank_name: formData.bank,
                bank_account_number: formData.accountNumber,
                account_holder_name: formData.accountHolder,
            }, {
                headers: { 'Content-Type': 'application/json' }
            });


            return response;
        } catch (err) {
             const errorMessage = err.response?.data?.error_msg
                || err.response?.data?.detail
                || '';
            setError("회원가입 실패 : " + errorMessage);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { partnerSignup, loading, error };
};

export default usePartnerSignup;
