import React, { useState, useContext } from "react";
import '../styles/Login.css';
import key_icon from '../assets/password.png';
import mail_icon from '../assets/email.png';
import { useNavigate } from 'react-router-dom';
import useLogin from "../components/customhook/useLogin";
import ParseJwt from "../components/ParseJwt";
import { UserContext } from '../UserContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, loading, error } = useLogin();
    const navigate = useNavigate();

    // UserContext에서 dispatch를 가져옴
    const { dispatch } = useContext(UserContext);

    const handleLogin = async () => {
        const token = await login(email, password);
        if (token) {
            localStorage.setItem('access_token', token);
            const userData = ParseJwt(token);  // 토큰에서 사용자 정보 파싱
            dispatch({ type: 'LOGIN', payload: userData });  // 상태 업데이트
            navigate('/');
        }
    };

    return (
        <div className='container'>
            <div className="header">
                <div className="text">Login</div>
                <div className="underline"></div>
            </div>
            <div className="inputs">
                <div className="input">
                    <img src={mail_icon} alt="email icon" />
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="input">
                    <img src={key_icon} alt="password icon" />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="forgot-password">Lost password? <span>Click here!</span></div>
            <div className="submit-container">
                <div className="submit" onClick={handleLogin} disabled={loading}>
                    {loading ? 'Loading...' : 'Login'}
                </div>
            </div>
        </div>
    );
}

export default Login;
