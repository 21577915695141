import React from 'react';
import '../../styles/Step.css';

const Step1 = ({formData, handleChange, handleNext}) => {

    const isValidEmail = (email) => {
        // 이메일 형식 검사 정규식
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isStep1Valid = () => {
        return isValidEmail(formData.email) && formData.name && formData.phone && formData.email && formData.password;
    };

    return (
        <div>
            <h2 className="page-title">회원 기본 정보</h2>
            <div className="notice">
                모든 필수 항목을 정확히 입력해 주세요.
            </div>
            <div className="inputs">
                <div className="input">
                    <input type="text" placeholder="이름" name="name" value={formData.name} onChange={handleChange}
                           required/>
                </div>
                <div className="input">
                    <input type="tel" placeholder="전화번호" name="phone" value={formData.phone} onChange={handleChange}
                           required/>
                </div>
                <div className="input">
                    <input type="email" placeholder="okx 가입한 이메일" name="email" value={formData.email} onChange={handleChange}
                           required/>
                </div>
                <div className="input">
                    <input type="password" placeholder="비밀번호" name="password" value={formData.password}
                           onChange={handleChange} required/>
                </div>
            </div>
            <div className="navigation-buttons">
                <button onClick={handleNext} disabled={!isStep1Valid()}>다음</button>
            </div>
        </div>
    );
};

export default Step1;
