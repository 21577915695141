import React from 'react';
import { formatDate } from './utils/FormatChange'

const OrderTable = ({ orders, onDetailView, user }) => {
  return (
    <table className="order-list-table">
      <thead>
        <tr>
          <th>주문 날짜</th>
          {user? <th>사용자</th> : null}
          <th>주문 금액</th>
          <th>상태</th>
          <th>상세보기</th>
        </tr>
      </thead>
      <tbody>
        {orders.length > 0 ? (
          orders.map(order => (
            <tr key={order.id}>
              <td>{formatDate(order.created_at)}</td>
              {user? <td>{order.user?.username}</td> : null}
              <td>{order.amount.toLocaleString()} 원</td>
              <td>{order.status}</td>
              <td>
                <button className="detail-button" onClick={() => onDetailView(order.id)}>
                  상세보기
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">주문 내역이 없습니다.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default OrderTable;
