import React from 'react';
import '../../styles/Step.css';

const Step5 = ({formData, handleSignup, loading}, handlePrevious) => {

    const keyMapping = {
        name: "이름",
        phone: "전화번호",
        email: "이메일",
        password: "비밀번호",
        referral: "추천인 코드",
        coinoneAccessKey: "코인원 Access Key",
        coinoneSecretKey: "코인원 Secret Key",
        coinoneWithdrawalAddress: "코인원 출금주소 등록",
        okxApiKey: "OKX API Key",
        okxSecretKey: "OKX Secret Key",
        okxPassphrase: "OKX Passphrase",
        okxusdtTrc20Address: "OKX USDT(TRC-20) 주소",
        okxWithdrawalAddress: "OKX 출금주소 등록",
        bank: "은행",
        accountNumber: "계좌번호",
        accountHolder: "예금주",
        kakaoBankAccount: "카카오뱅크 계좌번호"
    };

    return (
        <>
            <h2 className="page-title">정보 확인</h2>
            <div className="notice">
                모든 정보를 확인하고 정확하게 입력하셨다면 등록을 눌러주세요.
            </div>
            <div className="summary-container">
                <table className="summary-table">
                    <tbody>
                    {Object.entries(formData).map(([key, value]) => (
                        <tr key={key}>
                            <td className="key-column"><strong>{keyMapping[key] || key}</strong></td>
                            <td className="value-column">{value}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="navigation-buttons">
                <button onClick={handlePrevious}>이전으로</button>
                <button onClick={handleSignup}>등록</button>
            </div>
        </>
    );
};

export default Step5;
