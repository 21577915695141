import '../styles/Loading.css';  // CSS 파일을 임포트

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-text">로딩중...</div>
    </div>
  );
};

export default Loading;
